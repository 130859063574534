import React, { Key } from "react";
import clsx from "clsx";

import { User } from "@chef/icons/large";
import { RadioButtonBar, RadioButtonOption } from "../components";
import { Language } from "../../types";

const intl = {
  no: {
    PEOPLE: "Personer",
    AMOUNT: "st",
  },
  se: {
    PEOPLE: "Personer",
    AMOUNT: "st",
  },
  dk: {
    PEOPLE: "Personer",
    AMOUNT: "pers",
  },
};

interface PortionSizeSelectorProps<T> {
  name: string;
  options: RadioButtonOption<T>[];
  onChange: (value: T) => void;
  value: T;
  language: Language;
  className?: string;
}

const PortionSizeSelector = <T extends Key | null | undefined>({
  name,
  options,
  onChange,
  value,
  language = "no",
  className,
}: PortionSizeSelectorProps<T>) => {
  const { PEOPLE, AMOUNT } = intl[language];

  const selected = options.find((o) => o.value === value);

  return (
    <div
      className={clsx(
        "flex gap-3 md:gap-2 items-center justify-start",
        className,
      )}
    >
      <div className="flex flex-col items-center pl-3 md:pl-0 md:flex-row">
        <div className="md:mr-3">
          <User className="w-8 h-8" />
        </div>

        <div className="text-sm md:mr-4">
          <p className="hidden md:block">{PEOPLE}</p>
          <p>{selected && `${selected.name} ${AMOUNT}`}</p>
        </div>
      </div>

      <div>
        <RadioButtonBar
          name={`${name}-portions-size-selector`}
          options={options}
          onChange={onChange}
          value={value}
          small
          hasCheckmark
        />
      </div>
    </div>
  );
};

export default PortionSizeSelector;
