import React from "react";
import clsx from "clsx";

import { Close } from "@chef/icons/small";

import { AnimatedCheck } from "../AnimatedCheck";

interface ChipProps
  extends React.HTMLAttributes<HTMLButtonElement | HTMLDivElement> {
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  chipIcon?: "check" | "cross" | "none";
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

export const Chip = ({
  selected = false,
  highlighted = false,
  disabled = false,
  chipIcon = "check",
  onClick,
  className,
  children,
  id,
  ...props
}: ChipProps) => {
  const baseStyle =
    "h-9 inline-flex items-center px-3 !rounded-full text-sm disabled:opacity-50 a11y-focus:focus-ring";

  interface TypeStyles {
    default: string;
    selected: string;
    highlighted: string;
  }

  const typeStyles: TypeStyles = {
    default:
      "bg-grey-2 hover:bg-primary hover:text-white disabled:hover:text-black disabled:hover:bg-grey-2 disabled:cursor-not-allowed",
    selected: "bg-white border-solid border-2 border-primary",
    highlighted:
      "bg-white border-2 border-solid border-highlight hover:bg-highlight disabled:text-grey disabled:hover:text-black disabled:hover:bg-white disabled:hover:border-solid",
  };

  let type = "default" as string;

  if (selected) {
    type = "selected";
  }
  if (highlighted) {
    type = "highlighted";
  }

  const El = onClick ? "button" : "div";

  return (
    <El
      className={clsx(
        baseStyle,
        typeStyles[type as keyof TypeStyles],
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type="button"
      id={id}
      {...props}
    >
      {chipIcon === "check" && (
        <AnimatedCheck
          isChecked={selected}
          className={clsx(selected ? "mr-2" : "w-0")}
        />
      )}
      <span className="text-sm min-w-[1.5rem] whitespace-nowrap">
        {children}
      </span>
      {chipIcon === "cross" && selected && (
        <Close width={9} height={9} className="ml-2 " />
      )}
    </El>
  );
};
