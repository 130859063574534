import Image from "next/image";
import clsx from "clsx";

import { BASE_64_1X1_GREY, PreviewRecipe } from "@chef/constants";
import { findFeaturedPortraitImage } from "@chef/state-management/helpers";
import { isEven } from "@chef/utils/parity";

interface PreselectedRecipeProps {
  recipe: PreviewRecipe;
}

interface PreselectedRecipesListProps {
  recipes: PreviewRecipe[];
}

const PreselectedRecipe = ({ recipe }: PreselectedRecipeProps) => {
  return (
    <li className="flex items-center gap-3">
      <div className="relative w-15 h-15 shrink-0">
        <Image
          src={findFeaturedPortraitImage(recipe)?.url || BASE_64_1X1_GREY}
          fill
          alt={recipe.recipeName}
          className="object-cover rounded"
          sizes="60px"
        />
      </div>

      <div className="flex items-center text-sm text-left">
        {recipe.recipeName}
      </div>
    </li>
  );
};

export const PreselectedRecipesList = ({
  recipes,
}: PreselectedRecipesListProps) => {
  const hasEvenNumberOfRecipes = isEven(recipes.length);

  const recipesGradientStartIndex =
    recipes.length - (hasEvenNumberOfRecipes ? 2 : 1);

  const nonGradientRecipes = recipes.slice(0, recipesGradientStartIndex);
  const gradientRecipes = recipes.slice(recipesGradientStartIndex);

  return (
    <div>
      <ul className="grid md:grid-cols-2 gap-x-6 gap-y-3">
        {nonGradientRecipes.map((recipe) => (
          <PreselectedRecipe key={recipe.recipeId} recipe={recipe} />
        ))}
      </ul>

      <ul className="relative grid mt-3 md:grid-cols-2 gap-x-6 gap-y-3">
        {gradientRecipes.map((recipe) => (
          <PreselectedRecipe key={recipe.recipeId} recipe={recipe} />
        ))}

        <div
          className={clsx(
            hasEvenNumberOfRecipes ? "md:h-16 h-40" : "h-16",
            "absolute inset-0 z-0 bg-gradient-to-t from-white to-transparent",
          )}
        />
      </ul>
    </div>
  );
};
