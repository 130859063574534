type AppName = typeof process.env.APP_NAME;

let APP_NAME: AppName;

// Determine the value of APP_NAME based on the environment
if (typeof Cypress !== "undefined") {
  APP_NAME = Cypress.env("APP_NAME") as AppName;
} else {
  APP_NAME = process.env.APP_NAME as AppName;
}

export const BRAND_NAME = (
  {
    "adams-nextjs": "AMK",
    "godtlevert-nextjs": "GL",
    linasmatkasse2018: "LMK",
    "retnemt-nextjs": "RN",
    signin: "AMK", // Temporary to make builds work
    "pay.cheffelo.com": "AMK", // Temporary to make builds work
  } as const
)[APP_NAME];

export const language = (
  {
    AMK: "no",
    GL: "no",
    LMK: "se",
    RN: "dk",
  } as const
)[BRAND_NAME];

export const LOCALE = (
  {
    no: "nb-NO",
    se: "sv-SE",
    dk: "da-DK",
  } as const
)[language];

export const CURRENCY = (
  {
    AMK: "NOK",
    GL: "NOK",
    LMK: "SEK",
    RN: "DKK",
  } as const
)[BRAND_NAME];

export const FREE_SHIPPING_DISCOUNT_ID = (
  {
    GL: "DC67CE60-D06D-4930-BDB9-76AE17ECEA32",
    AMK: "BFCD25A3-0ADC-484C-8496-971D7EAE5084",
    LMK: "854ACE09-6660-4574-81EF-E1398462697E",
    RN: "D2DEACAB-1D40-4246-A5B7-FA7B43D7B05E",
  } as const
)[BRAND_NAME];

export const BRAND_NAME_FULL = (
  {
    "adams-nextjs": "Adams Matkasse",
    "godtlevert-nextjs": "Godtlevert",
    linasmatkasse2018: "Linas Matkasse",
    "retnemt-nextjs": "RetNemt",
    "pay.cheffelo.com": "Cheffelo Payment",
  } as const
)[APP_NAME];

export const BRAND_DOMAIN = (
  {
    "adams-nextjs": "adamsmatkasse.no",
    "godtlevert-nextjs": "godtlevert.no",
    linasmatkasse2018: "linasmatkasse.se",
    "retnemt-nextjs": "retnemt.dk",
    "pay.cheffelo.com": "pay.cheffelo.com",
  } as const
)[APP_NAME];

export const BRAND_LOGGED_IN_START_PAGE = (
  {
    "adams-nextjs": "/app/start",
    "godtlevert-nextjs": "/app/start",
    linasmatkasse2018: "/mina-sidor/start",
    "retnemt-nextjs": "/app/start",
    "pay.cheffelo.com": "/account",
  } as const
)[APP_NAME];

export type BRAND_NAME = typeof BRAND_NAME;

export const BASE_64_1X1_GREY =
  "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

export const STOCK_SOLD_OUT_STATES = {
  LIMITED_STOCK: "limited-stock",
  MAXIMUM_AMOUNT_ADDED: "maximum-amount-added",
  SOLD_OUT: "sold-out",
} as const;

export const PASSWORD_MIN_LENGTH = 5;
export const NEW_PASSWORD_MIN_LENGTH = 8;

export type WEEKDAY = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum VISIBILITY {
  PUBLIC = 0b0001,
  PRIVATE = 0b0010,
  ALL = 0b0011,
}
