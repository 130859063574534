import { createContext, useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "@chef/helpers";
import { BRAND_NAME } from "@chef/constants";

import {
  selectSignupCoupon,
  setSignupAddonSubscriptions,
  setSignupAffiliateId,
  setSignupCoupon,
  useMeQuery,
  useSubmitCouponMutation,
} from "../../features";
import { useLazyAddRkQuery, useLazyAddSidQuery } from "../../features/discount";
import { IAddDiscount, IAddDiscountArgs } from "../../features/discount/types";
import { useLazyDefaultCouponQuery } from "../../graphql/generated";

interface DiscountsProviderProps {
  children: React.ReactNode;
}

export const DiscountsContext = createContext<{
  setOverride: (arg: IAddDiscountArgs) => void;
  isLoading: boolean;
  isError: boolean;
  isUninitialized: boolean;
  magic: Map<
    | "MAX_SAVINGS"
    | "MAX_SAVINGS_PER_PORTION"
    | "MIN_PRICE_PER_PORTION"
    | "AMOUNT"
    | "TYPE"
    | "USES_LEFT"
    | "EXPIRATION_DATE",
    string
  >;
} | null>(null);

const calculateDiscountValues = (discount: IAddDiscount) => {
  let maxSavings = 0;
  let maxUsages = 0;

  const { chainedDiscounts: children, ...parent } = discount;

  let chain = [];

  if (children) {
    chain = [parent, ...children];
  } else {
    chain = [parent];
  }

  let maxSaving = 0;

  for (const e of chain) {
    const { amount, type, usesLeft, initialUsage } = e;

    let _amount = 0;

    if (type === 2) {
      _amount = amount;
    } else {
      _amount = (amount * brand.MAX_MEALBOX_VALUE) / 100;
    }

    _amount *= usesLeft || initialUsage || 0;

    if (_amount > maxSaving) {
      maxSaving = _amount;
    }

    maxSavings += _amount;
    maxUsages += usesLeft || initialUsage || 0;
  }

  return { maxSavings, maxUsages, maxSaving };
};

export const DiscountsProvider = ({ children }: DiscountsProviderProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: user } = useMeQuery();

  const [overrides, setOverrides] = useState<IAddDiscountArgs>({});

  const currentlyAppliedCouponDetails = useSelector(selectSignupCoupon);

  const [lazyDefaultCouponQuery, defaultCouponQueryResult] =
    useLazyDefaultCouponQuery();

  const [submitCouponMutation, submitCouponQueryResult] =
    useSubmitCouponMutation();

  const [lazyAddRkQuery, addRkQueryResult] = useLazyAddRkQuery();
  const [lazyAddSidQuery, addSidQueryResult] = useLazyAddSidQuery();

  // Maximum possible value of the most valuable discount in the chain
  const [maxSaving, setMaxSaving] = useState(0);

  // Total maximum possible value of all discounts in the chain
  const [maxSavings, setMaxSavings] = useState(0);
  const [maxUsages, setMaxUsages] = useState(0);

  const rk = overrides.rk ?? router.query["rk"];
  const rb = overrides.rb ?? router.query["rb"];
  const sid = overrides.sid ?? router.query["sid"];

  const at_gd = router.query["at_gd"];
  const adn_sub = router.query["adn_sub"];

  const isLoggedIn = !!user?.agreementId;

  const setOverride = useCallback(
    (arg: IAddDiscountArgs) => {
      for (const key in arg) {
        setOverrides((prev) => ({
          ...prev,
          [key]: arg[key as keyof IAddDiscountArgs],
        }));
      }
    },
    [setOverrides],
  );

  useEffect(() => {
    if (!at_gd) {
      return;
    }

    dispatch(setSignupAffiliateId({ at: at_gd as string }));
  }, [at_gd]);

  useEffect(() => {
    if (!adn_sub) {
      return;
    }

    if (typeof adn_sub === "string") {
      dispatch(setSignupAddonSubscriptions([adn_sub]));
    }

    if (Array.isArray(adn_sub)) {
      dispatch(setSignupAddonSubscriptions(adn_sub));
    }
  }, [adn_sub]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    let cancelled = false;

    (async () => {
      let data;
      let nullSidDiscount = false;

      try {
        if (sid) {
          const _data = await lazyAddSidQuery(sid as string).unwrap();
          data = { ..._data, salesPointId: sid as string };

          if (data.discountId === null) {
            nullSidDiscount = true;
            throw new Error(`No discount on sid ${sid}`);
          }

          // If we are logged in, add the coupon to the user's account and escape
          if (isLoggedIn) {
            await submitCouponMutation({
              couponCode: data.couponCode,
            }).unwrap();

            return;
          }
        } else if (rk && !isLoggedIn) {
          data = await lazyAddRkQuery(rk as string).unwrap();

          if (data.discountId === null) {
            throw new Error(`No discount on rk ${rk}`);
          }
        } else if (rb && isLoggedIn) {
          await submitCouponMutation({
            couponCode: rb as string,
          }).unwrap();

          // Stop here if its a logged in coupon
          return;
        } else {
          throw new Error("No discount code provided");
        }
      } catch {
        if (isLoggedIn) {
          return;
        }
        const coupon = await lazyDefaultCouponQuery(undefined, true).unwrap();

        data = {
          ...coupon.defaultCoupon,
          source: "default",
        } as IAddDiscount;

        if (nullSidDiscount) {
          data = {
            ...coupon.defaultCoupon,
            salesPointId: sid as string,
            source: "sid",
          } as IAddDiscount;
        }
      }

      if (!data) {
        return;
      }

      // If the queried coupon is registration only, and we're logged in,
      // then we should not add it to state
      const isRegistrationOnly =
        data.registrationOnly || data.registration_only;
      if (isRegistrationOnly && isLoggedIn) {
        return;
      }

      // Unless the currently applied discount is from default coupon, if the queried
      // discount is less valuable than the current discount,
      // then we should not add it to state. If the queried discount is flat, we
      // ignore this logic.
      const isFlatDiscount = data.type === 2;

      const potentialDiscountValue =
        data.amount * (data.usesLeft || data.initialUsage || 0);

      const currentlyAppliedDiscountValue =
        (currentlyAppliedCouponDetails?.amount || 0) *
        (currentlyAppliedCouponDetails?.usesLeft ||
          currentlyAppliedCouponDetails?.initialUsage ||
          0);

      if (
        currentlyAppliedCouponDetails?.source !== "default" &&
        !isFlatDiscount &&
        potentialDiscountValue < currentlyAppliedDiscountValue
      ) {
        return;
      }

      // If the queried discount is from default coupon and the currently applied discount isn't,
      // don't add it to state
      if (
        data.source === "default" &&
        (currentlyAppliedCouponDetails?.source === "sid" ||
          currentlyAppliedCouponDetails?.source === "rb" ||
          currentlyAppliedCouponDetails?.source === "rk")
      ) {
        return;
      }

      // If the useEffect got ran again from somewhere else, we don't want to add the discount to state
      if (cancelled) {
        return;
      }

      if (!data.couponCode) {
        dispatch(
          setSignupCoupon({
            ...data,
            couponCode: (rk || rb) as string,
            userInitiated: overrides.userInitiated,
          }),
        );
      } else {
        dispatch(
          setSignupCoupon({ ...data, userInitiated: overrides.userInitiated }),
        );
      }

      const values = calculateDiscountValues(data);

      setMaxSaving(values.maxSaving);
      setMaxSavings(values.maxSavings);
      setMaxUsages(values.maxUsages);
    })().catch((err) => {
      // TODO: add support here https://app.datadoghq.eu/rum/explorer?query=%40application.id%3A6b406952-3620-408f-a11d-d0b7ff4e45e3%20version%3A5.0.0%20%40type%3Aerror&cols=&event=AQAAAYPmOakt8_7QrAAAAABBWVBtT2JjeUFBQU5DVlRHN3NtSnNRQUc&p_tab=error_details&viz=stream_issues&from_ts=1665929669079&to_ts=1666016069079&live=true
      console.error({ rk, rb, sid, isLoggedIn }, err);
    });

    return () => {
      cancelled = true;
    };
  }, [router.isReady, isLoggedIn, rk, rb, sid]);

  const isError =
    defaultCouponQueryResult.isError ||
    addRkQueryResult.isError ||
    addSidQueryResult.isError ||
    submitCouponQueryResult.isError;

  const isLoading =
    defaultCouponQueryResult.isLoading ||
    addRkQueryResult.isLoading ||
    addSidQueryResult.isLoading ||
    submitCouponQueryResult.isLoading;

  const isUninitialized = !currentlyAppliedCouponDetails;

  const magic = new Map();

  magic.set("MAX_SAVINGS", maxSavings.toString());
  magic.set(
    "MAX_SAVINGS_PER_PORTION",
    (maxSaving / brand.MAX_TOTAL_PORTIONS).toString(),
  );
  magic.set(
    "MIN_PRICE_PER_PORTION",
    (
      (brand.MAX_MEALBOX_VALUE - maxSaving) /
      brand.MAX_TOTAL_PORTIONS
    ).toString(),
  );
  magic.set("AMOUNT", (currentlyAppliedCouponDetails?.amount || 0).toString());
  magic.set("USES_LEFT", maxUsages.toString());
  magic.set("TYPE", currentlyAppliedCouponDetails?.type === 2 ? "kr" : "%");
  magic.set(
    "EXPIRATION_DATE",
    formatDate(currentlyAppliedCouponDetails?.validEndDate || 0, undefined, {
      lowerCase: true,
    }),
  );

  return (
    <DiscountsContext.Provider
      value={{ setOverride, isLoading, isError, isUninitialized, magic }}
    >
      {children}
    </DiscountsContext.Provider>
  );
};

const brand = (
  {
    AMK: {
      MAX_MEALBOX_VALUE: 1889,
      MAX_TOTAL_PORTIONS: 30,
    },
    GL: {
      MAX_MEALBOX_VALUE: 1579,
      MAX_TOTAL_PORTIONS: 30,
    },
    LMK: {
      MAX_MEALBOX_VALUE: 1639,
      MAX_TOTAL_PORTIONS: 30,
    },
    RN: {
      MAX_MEALBOX_VALUE: 1389,
      MAX_TOTAL_PORTIONS: 30,
    },
  } as const
)[BRAND_NAME];
