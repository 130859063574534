import { useState } from "react";

import { Tag, Hr } from "@chef/components";
import { language } from "@chef/constants";

import { CurrencyWrapper as Currency } from "../../CurrencyWrapper";

import { DiscountInfo } from "../../DiscountInfo";
import { Chevron } from "./Chevron";

interface DiscountProps {
  discount: {
    type: string;
    amount: string;
  };

  type: "permanent" | "temporary";
}

export const Discount = ({ discount, type }: DiscountProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Hr className="my-4" />

      <div className="flex flex-col">
        <button
          className="flex justify-between"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center">
            <p>{intl.UPCOMING_DISCOUNT}</p>

            <Chevron className={expanded ? "rotate-180" : undefined} />
          </div>

          {discount.type === "%" && (
            <Tag highlight className="px-1.5 text-sm">
              <strong>{discount.amount} %</strong>
            </Tag>
          )}

          {discount.type === "kr" && <Currency>{discount.amount}</Currency>}
        </button>

        {expanded && <DiscountInfo type={type} />}
      </div>
    </>
  );
};

const intl = (
  {
    no: {
      UPCOMING_DISCOUNT: "Kommende rabatt",
    },

    se: {
      UPCOMING_DISCOUNT: "Kommande rabatt",
    },

    dk: {
      UPCOMING_DISCOUNT: "Kommende rabat",
    },
  } as const
)[language];
