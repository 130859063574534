import { Fragment, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";

import { Button, BurgerButton, Disclosure } from "@chef/components";
import { useIntercom } from "@chef/hooks";
import {
  selectDrawerModalIsOpen,
  hideDrawerModal,
  useLogoutMutation,
  useMeQuery,
} from "@chef/state-management";
import { BRAND_PATHS, VISIBILITY } from "@chef/constants";
import { LoyaltyInfoCard } from "@chef/smart-components";
import { useTrack } from "@chef/feature-tracking";
import { isEqualStrings } from "@chef/utils/equal";

import { Logo } from "../../Logo";
import { DrawerButton } from "./DrawerButton";
import { useGetNavItems } from "../../../utils";

import { intl } from "./Drawer.Intl";

interface DrawerProps {
  hidden?: boolean;
}

const sidebar = {
  open: {
    display: "block",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    display: "none",
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const Drawer = ({ hidden = false }: DrawerProps) => {
  const [scrollY, setScrollY] = useState(0);

  const dispatch = useDispatch();
  const router = useRouter();
  const [logout] = useLogoutMutation();
  const containerRef = useRef(null);
  const track = useTrack();

  const { data: isLoggedIn } = useMeQuery();

  const isOpen = useSelector(selectDrawerModalIsOpen);

  useIntercom(!isOpen);

  useEffect(() => {
    router.events.on("routeChangeStart", handleClose);

    return () => {
      router.events.off("routeChangeStart", handleClose);
    };
  }, []);

  useEffect(() => {
    setScrollY(window.scrollY);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${scrollY}px`;
      document.body.classList.add("disable-scroll");
    } else {
      document.body.style.top = "";
      document.body.classList.remove("disable-scroll");
    }
  }, [isOpen, scrollY]);

  const handleClose = () => {
    dispatch(hideDrawerModal());
  };

  const handleShowLoginModal = () => {
    dispatch(hideDrawerModal());
  };

  const { drawerItems } = useGetNavItems();

  const DRAWER_ITEMS_BY_CATEGORY = drawerItems.reduce((acc, cur) => {
    if (!cur.category) {
      acc.push({ category: undefined, items: [cur] });
      return acc;
    }
    const idx = acc.findIndex((item) =>
      isEqualStrings(item.category, cur.category),
    );

    if (idx !== -1) {
      acc[idx].items.push(cur);
    } else {
      acc.push({ category: cur.category, items: [cur] });
    }

    return acc;
  }, [] as { category?: string; items: typeof drawerItems }[]);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
    >
      {!hidden && <DrawerButton />}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-45 bg-black/50 backdrop-blur-sm"
            onClick={handleClose}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
          />
        )}
      </AnimatePresence>

      <motion.div
        variants={sidebar}
        className="fixed top-0 bottom-0 right-0 z-50 w-screen h-full overflow-y-auto shadow bg-grey-3 md:w-96"
        drag="x"
        onDragEnd={(_, { offset }) => {
          if (offset.x > 100) {
            handleClose();
          }
        }}
        dragConstraints={{ left: 0, right: 1 }}
        dragElastic={{ left: 0, right: 0.8 }}
        style={{ touchAction: "none" }}
      >
        <div>
          <div className="flex items-center justify-between gap-8 p-4 h-15 bg-primary">
            <Logo variant="inverted" />
            <BurgerButton
              onClick={handleClose}
              className="rounded stroke-white hover:bg-grey-2/75 hover:stroke-black"
              title={intl.OPEN_MENU_LABEL}
            />
          </div>
          {isLoggedIn && (
            <Link href={BRAND_PATHS.LOYALTY_HREF}>
              <LoyaltyInfoCard
                className="bg-grey-2"
                showInfo={false}
                showNudge
              />
            </Link>
          )}
        </div>

        <motion.ul
          variants={{
            open: {
              transition: { staggerChildren: 0.03, delayChildren: 0.1 },
            },
            closed: {
              transition: { staggerChildren: 0.01, staggerDirection: -1 },
            },
          }}
          className="flex flex-col justify-between px-4 pt-2 pb-6"
        >
          {DRAWER_ITEMS_BY_CATEGORY.map(({ category, items }, i) => {
            if (!category) {
              return (
                <Fragment key={i}>
                  {items.map((item) => {
                    return (
                      <li
                        className="relative border-b-1.5 border-grey-1"
                        key={`${item.href}-${item.label}`}
                        id={`${item.label}-drawer-link`}
                      >
                        <Link
                          href={item.href}
                          className="flex items-center py-4 text-base group"
                          passHref
                        >
                          {item.iconHref && (
                            <Image
                              src={item.iconHref}
                              alt={""}
                              width={26}
                              height={26}
                              className="ml-1 mr-3"
                            />
                          )}
                          {item.empasize ? (
                            <strong>{item.label}</strong>
                          ) : (
                            <span>{item.label}</span>
                          )}
                          {item.campaignText && (
                            <div className="px-2 py-1 text-xs bg-highlight">
                              {item.campaignText}
                            </div>
                          )}
                        </Link>
                      </li>
                    );
                  })}
                </Fragment>
              );
            }
            return (
              <li
                className="col-span-full md:col-span-1 border-b-1.5 border-grey-1"
                key={category}
              >
                <nav>
                  <Disclosure
                    className="relative block py-4"
                    key={category}
                    Title={() => <span className="text-base">{category}</span>}
                    defaultOpen={i === 0}
                  >
                    <ul className="flex flex-col -mt-4">
                      {items
                        .filter(
                          ({ visibility }) =>
                            visibility &
                            (isLoggedIn
                              ? VISIBILITY.PRIVATE
                              : VISIBILITY.PUBLIC),
                        )
                        .map((item) => {
                          return (
                            <li
                              key={`${item.href}-${item.label}`}
                              id={`${item.label}-drawer-link`}
                              className="relative block ml-5"
                            >
                              <Link
                                href={item.href}
                                className="flex items-center py-2 text-base"
                              >
                                {item.label}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </Disclosure>
                </nav>
              </li>
            );
          })}
        </motion.ul>

        <div className="flex flex-col px-4">
          {isLoggedIn ? (
            <Button
              outlined
              className="mx-4 my-4 no-underline bg-white"
              onClick={() => {
                logout();
              }}
            >
              {intl.LOG_OUT}
            </Button>
          ) : (
            <>
              <Link
                onClick={() =>
                  track("linkClicked", {
                    affiliation: "Frontend process",
                    link_text: intl.CREATE_ACCOUNT,
                    link_placement: "drawer",
                    link_source: "code",
                    link_action: "click",
                    link_destination: BRAND_PATHS.SIGNUP_HREF,
                  })
                }
                href={BRAND_PATHS.SIGNUP_HREF}
                passHref
              >
                <Button primary className="mt-4 no-underline" full>
                  {intl.CREATE_ACCOUNT}
                </Button>
              </Link>

              <Button
                href={BRAND_PATHS.LOG_IN_URL}
                onClick={handleShowLoginModal}
                id="burger-menu-login-button"
                className="my-4 no-underline bg-white"
                full
                outlined
              >
                {intl.LOG_IN}
              </Button>
            </>
          )}
        </div>
      </motion.div>
    </motion.nav>
  );
};
