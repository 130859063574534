import { getNextCutoffs, getWeekYearSortableNumber } from "./time";

export const createReactivationSchedulers = (week: number, year: number) => {
  const nextCutoffs = getNextCutoffs();

  // Create schedulers setting the delivery to false for weeks preceding the current week,
  // and to true for the current week
  const schedulers: {
    week: number;
    year: number;
    delivery: boolean;
  }[] = nextCutoffs
    .filter(
      (cutoff) =>
        getWeekYearSortableNumber(cutoff) <
        getWeekYearSortableNumber({ week, year }),
    )
    .map((cutoff) => ({
      week: cutoff.week,
      year: cutoff.year,
      delivery: false,
    }));

  schedulers.push({ week, year, delivery: true });

  return schedulers;
};
