import Link from "next/link";
import { useRouter } from "next/router";
import clsx from "clsx";

import { Card } from "@chef/components";
import { useViewport } from "@chef/hooks";
import { useMeQuery } from "@chef/state-management";
import { isEmptyArray } from "@chef/utils/array";
import { useTrack } from "@chef/feature-tracking";
import { BRAND_NAME, BRAND_PATHS } from "@chef/constants";
import {
  Calendar,
  Complaint,
  Dinner,
  ReceiptDefault,
  User,
} from "@chef/icons/small";

import { isActivePath } from "../../utils";

import { intl } from "./BottomTabBar.Intl";

const brand = {
  AMK: {
    items: [
      {
        label: intl.PLAN,
        href: BRAND_PATHS.MY_MENU_PAGE,
        icon: <Dinner className="w-6 h-6" />,
      },
      {
        label: intl.CALENDAR,
        href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
        icon: <Calendar className="w-6 h-6" />,
      },
      {
        label: intl.RECIPES,
        href: BRAND_PATHS.MY_RECIPES_PAGE,
        icon: <ReceiptDefault className="w-8 h-8" />,
      },
      {
        label: intl.ACCOUNT,
        href: BRAND_PATHS.MY_ACCOUNT_HREF,
        icon: <User className="w-10 h-10" />,
      },
      {
        label: intl.CUSTOMER_SERVICE,
        href: BRAND_PATHS.CONTACT_US_PAGE,
        icon: <Complaint className="w-6 h-6" />,
      },
    ],
  },
  GL: {
    items: [
      {
        label: intl.PLAN,
        href: BRAND_PATHS.MY_MENU_PAGE,
        icon: <Dinner className="w-6 h-6" />,
      },
      {
        label: intl.CALENDAR,
        href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
        icon: <Calendar className="w-6 h-6" />,
      },
      {
        label: intl.RECIPES,
        href: BRAND_PATHS.MY_RECIPES_PAGE,
        icon: <ReceiptDefault className="w-8 h-8" />,
      },
      {
        label: intl.ACCOUNT,
        href: BRAND_PATHS.MY_ACCOUNT_HREF,
        icon: <User className="w-10 h-10" />,
      },
      {
        label: intl.CUSTOMER_SERVICE,
        href: BRAND_PATHS.CONTACT_US_PAGE,
        icon: <Complaint className="w-6 h-6" />,
      },
    ],
  },
  LMK: {
    items: [
      {
        label: intl.PLAN,
        href: BRAND_PATHS.MY_MENU_PAGE,
        icon: <Dinner className="w-6 h-6" />,
      },
      {
        label: intl.CALENDAR,
        href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
        icon: <Calendar className="w-6 h-6" />,
      },
      {
        label: intl.RECIPES,
        href: BRAND_PATHS.MY_RECIPES_PAGE,
        icon: <ReceiptDefault className="w-8 h-8" />,
      },
      {
        label: intl.ACCOUNT,
        href: BRAND_PATHS.MY_ACCOUNT_HREF,
        icon: <User className="w-10 h-10" />,
      },
      {
        label: intl.CUSTOMER_SERVICE,
        href: BRAND_PATHS.CONTACT_US_PAGE,
        icon: <Complaint className="w-6 h-6" />,
      },
    ],
  },
  RN: {
    items: [
      {
        label: intl.PLAN,
        href: BRAND_PATHS.MY_MENU_PAGE,
        icon: <Dinner className="w-6 h-6" />,
      },
      {
        label: intl.CALENDAR,
        href: BRAND_PATHS.DELIVERY_CALENDAR_UPCOMING_LINK,
        icon: <Calendar className="w-6 h-6" />,
      },
      {
        label: intl.RECIPES,
        href: BRAND_PATHS.MY_RECIPES_PAGE,
        icon: <ReceiptDefault className="w-8 h-8" />,
      },
      {
        label: intl.ACCOUNT,
        href: BRAND_PATHS.MY_ACCOUNT_HREF,
        icon: <User className="w-10 h-10" />,
      },
      {
        label: intl.CUSTOMER_SERVICE,
        href: BRAND_PATHS.CONTACT_US_PAGE,
        icon: <Complaint className="w-6 h-6" />,
      },
    ],
  },
} as const;

const items = brand[BRAND_NAME].items;

export const BottomTabBar = () => {
  const { data: isLoggedIn } = useMeQuery();
  const router = useRouter();
  const track = useTrack();

  const [breakpoint] = useViewport();

  if (isEmptyArray(items) || breakpoint !== "sm" || !isLoggedIn) {
    return null;
  }

  if (breakpoint === "sm") {
    document.body.classList.add("browser-intercom-bubble-bottom-margin");
  } else {
    document.body.classList.remove("browser-intercom-bubble-bottom-margin");
  }

  const gridCols = items.length > 4 ? "grid-cols-5" : "grid-cols-4";

  const handleClick = (label: string, href: string) => {
    track("linkClicked", {
      affiliation: "Frontend process",
      link_action: "click",
      link_destination: href,
      link_placement: "bottom_tab_bar",
      link_source: "code",
      link_text: label,
    });
  };

  return (
    <div className="pb-20 md:hidden">
      <Card
        noPadding
        className="fixed bottom-0 left-0 right-0 z-10 w-full h-20 pr-2 print:hidden"
      >
        <nav
          className={clsx(
            "grid content-center w-full h-full  text-center",
            gridCols,
          )}
          id="tab-bar"
        >
          {items.slice(0, 5).map(({ label, href, icon }, index, items) => {
            const isActive = isActivePath(
              router.pathname,
              href,
              items.map((i) => i.href),
            );

            return (
              <Link
                key={href}
                href={href}
                passHref
                className="col-span-1 text-center"
                id={"bottom_tab_bar_" + index}
                onClick={() => handleClick(label, href)}
              >
                <div
                  className={clsx(
                    isActive && "opacity-100",
                    !isActive && "opacity-60",
                  )}
                >
                  <div
                    className={clsx(
                      isActive && "opacity-100",
                      "w-10 h-10 m-auto align-bottom content-center",
                    )}
                  >
                    {icon}
                  </div>

                  <div className="text-xs">{label}</div>
                </div>
              </Link>
            );
          })}
        </nav>
      </Card>
    </div>
  );
};
