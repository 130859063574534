import { getNextNYears } from "@chef/helpers";

type YearOption = {
  title: string;
  value: number;
};

export const getListOfUpcomingDeliveryYears = (): YearOption[] => {
  return getNextNYears().map((year) => ({
    title: year.toString(),
    value: year,
  }));
};
