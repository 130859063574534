import clsx from "clsx";
import { useState } from "react";

import { Disclosure, Hr } from "@chef/components";
import { language } from "@chef/constants";
import { OrderQuery } from "@chef/state-management";

import { CurrencyWrapper as Currency } from "../../CurrencyWrapper";

import { Chevron } from "../shared/Chevron";

export const OrderDiscount = ({ order }: { order: OrderQuery["order"] }) => {
  const [expanded, setExpanded] = useState(false);

  const discountProducts = order.orderLines.filter(
    (ol) =>
      ol.type === "Discount" ||
      ol.type === "PRP" ||
      ol.type === "Credit" ||
      ol.type === "Loyalty_Credit",
  );

  if (discountProducts.length === 0) {
    return null;
  }

  const totalDiscount = discountProducts.reduce(
    (acc, curr) => acc + curr.fullPrice,
    0,
  );

  return (
    <>
      <Hr className="my-4" />

      <Disclosure
        open={expanded}
        onClick={() => setExpanded(!expanded)}
        HeaderRow={() => (
          <div className="flex justify-between grow">
            <div className="flex items-center gap-2">
              <p>{intl.DISCOUNT}</p>

              <Chevron className={clsx(expanded && "rotate-180")} />
            </div>

            <Currency>{totalDiscount}</Currency>
          </div>
        )}
      >
        <div>
          {discountProducts.map((orderLine) => {
            return (
              <div className="flex justify-between" key={orderLine.variationId}>
                <p>{getDiscountName(orderLine)}</p>
                <Currency>{orderLine.fullPrice}</Currency>
              </div>
            );
          })}
        </div>
      </Disclosure>
    </>
  );
};

const getDiscountName = (
  orderLine: OrderQuery["order"]["orderLines"][number],
) => {
  switch (orderLine.type) {
    case "PRP":
      return intl.REFUND;
    case "Credit":
      return intl.CREDIT;
    default:
      return intl.DISCOUNT;
  }
};

const intl = (
  {
    no: {
      DISCOUNT: "Rabatt",
      REFUND: "Refusjon",
      CREDIT: "Kreditt",
    },

    se: {
      DISCOUNT: "Rabatt",
      REFUND: "Återbetalning",
      CREDIT: "Kredit",
    },

    dk: {
      DISCOUNT: "Rabat",
      REFUND: "Refusion",
      CREDIT: "Kredit",
    },
  } as const
)[language];
