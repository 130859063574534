import { useRouter } from "next/router";

import { Button } from "@chef/components";
import { BRAND_PATHS } from "@chef/constants";
import { createReactivationSchedulers, isAccountPaused } from "@chef/helpers";
import { useConfirmDialog } from "@chef/hooks";
import { useBillingQuery } from "@chef/state-management";
import {
  getCurrentWeekDataAndBasketFromCalendar,
  useNavigateWithDeviationCheck,
} from "@chef/state-management/helpers";
import { useCalendarData } from "@chef/state-management/hooks";

import { AccountReactivationModal } from "./AccountReactivationModal";

interface AccountReactivationButtonProps {
  children: React.ReactNode;
  week: number;
  year: number;
  full?: boolean;
}

export const AccountReactivationButton = ({
  children,
  week,
  year,
  full,
}: AccountReactivationButtonProps) => {
  const router = useRouter();

  const { basket: calendarBasketProducts, calendar } = useCalendarData({
    week,
    year,
  });

  const navigateWithDeviationCheck = useNavigateWithDeviationCheck({
    week,
    year,
    calendarBasketProducts,
  });

  const currentWeekObject = getCurrentWeekDataAndBasketFromCalendar({
    calendar: calendar || [],
    week,
    year,
  });

  const isBeforeStartDateWeek = currentWeekObject?.isBeforeStartDateWeek;

  const { data: billingQuery } = useBillingQuery();

  const isPausedAccount = isAccountPaused(billingQuery?.billing.status || 10);

  const { isShowing, handleCancel, handleConfirm, handleShow } =
    useConfirmDialog();

  const handleGoToWeekEditor = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (isBeforeStartDateWeek) {
      e.preventDefault();
      return;
    }

    if (isPausedAccount) {
      handleShow();
      return;
    }

    const shouldAbortNavigation = await navigateWithDeviationCheck(
      BRAND_PATHS.WEEK_EDITOR_PAGE({ week, year }),
    );

    if (!shouldAbortNavigation) {
      router.push(BRAND_PATHS.WEEK_EDITOR_PAGE({ week, year }));
    }
  };

  return (
    <>
      <Button
        onClick={handleGoToWeekEditor}
        className="my-2 md:mt-0"
        data-intercom-target="to-week-editor"
        id="to-week-editor"
        full={full}
        primary
      >
        {children}
      </Button>

      <AccountReactivationModal
        redirectUrl={BRAND_PATHS.WEEK_EDITOR_PAGE({ week, year })}
        show={isShowing}
        onClose={handleCancel}
        handleConfirm={handleConfirm}
        schedulerUpdates={createReactivationSchedulers(week, year)}
      />
    </>
  );
};
