import clsx from "clsx";

import { Tag } from "@chef/components";

import { TAXONOMY_IDS } from "@chef/constants";
import { RecipeAndStepsQuery } from "@chef/state-management";
import { generateTags } from "@chef/state-management/helpers";

interface CampaignTagProps {
  recipe: RecipeAndStepsQuery["recipeAndSteps"];
}

export const CampaignTag = ({ recipe }: CampaignTagProps) => {
  const { campaign } = generateTags(recipe);

  if (!campaign?.text) {
    return null;
  }

  const isWeightWatchers =
    TAXONOMY_IDS.WEIGHT_WATCHERS &&
    campaign.id === TAXONOMY_IDS.WEIGHT_WATCHERS.toString();

  return (
    <Tag white className="normal-case">
      <div
        className={clsx(
          "mr-2 circle",
          isWeightWatchers ? "bg-[#06167B]" : "bg-highlight",
        )}
      />
      <p>{campaign?.text}</p>
    </Tag>
  );
};
