import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { BRAND_DOMAIN, BRAND_PATHS } from "@chef/constants";
import { isAccountBadPayer, isAccountPending } from "@chef/helpers";
import { useBillingQuery, useMeQuery } from "@chef/state-management";
import { createDefaultOgImage } from "@chef/utils/images";

import AccountPausedBanner from "./AccountPausedBanner";
import { OldVippsBanner } from "./OldVippsBanner";
import { DiscountBanner } from "./DiscountBanner";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Main } from "./Main";
import { Meta } from "./Meta";
import { BottomTabBar } from "./TabBar";

interface AppShellProps {
  title: string;
  description?: string;
  canonical?: string;
  index?: boolean;
  children: React.ReactNode;
  image?: string;
  keywords?: string;
  brandedTitle?: boolean;
}

interface AppShellContextValues {
  title: string;
  description?: string;
  canonical?: string;
  index?: boolean;
  image?: string;
  keywords?: string;
  brandedTitle?: boolean;
}

const ALLOWED_BAD_PAYER_PATHS = new Set<string>([
  BRAND_PATHS.CONTACT_US_PAGE,
  BRAND_PATHS.PAYMENT_LINK,
]);
const ALLOWED_PENDING_PATHS = new Set<string>([
  BRAND_PATHS.CONTACT_US_PAGE,
  BRAND_PATHS.PAYMENT_LINK,
]);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const AppShellContext = React.createContext<AppShellContextValues>({
  title: null!,
  description: null!,
  canonical: null!,
  index: true,
  image: null!,
  keywords: null!,
  brandedTitle: true,
});
/* eslint-enable @typescript-eslint/no-non-null-assertion */

const createCanonicalHref = (uri?: string) => {
  let _uri = uri || "";

  // Next.js might add /index to the end of the path when it's a directory in production
  if (_uri.startsWith("/index")) {
    _uri = _uri.replace("/index", "/");
  }

  // If the URI is absolute, we don't need to do anything
  if (_uri.startsWith("http")) {
    return _uri;
  }

  const url = new URL(`https://www.${BRAND_DOMAIN}${uri}`);

  return `${url.origin}${url.pathname}`;
};

export const AppShell = ({
  title,
  description,
  canonical,
  index = true,
  children,
  image,
  keywords,
  brandedTitle = true,
}: AppShellProps) => {
  const router = useRouter();
  const { data: isLoggedIn } = useMeQuery();
  const { data: billingQuery } = useBillingQuery(undefined, {
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (!billingQuery) {
      return;
    }

    const isBadPayer = isAccountBadPayer(billingQuery.billing.status);
    const isPending = isAccountPending(billingQuery.billing.status);

    if (isBadPayer && !ALLOWED_BAD_PAYER_PATHS.has(router.pathname)) {
      router.push(BRAND_PATHS.CONTACT_US_PAGE);
    }

    if (isPending && !ALLOWED_PENDING_PATHS.has(router.pathname)) {
      router.push(BRAND_PATHS.PAYMENT_LINK);
    }
  }, [billingQuery, router]);

  const defaultOgImage = createDefaultOgImage({ urlOrDomain: BRAND_DOMAIN });

  return (
    <AppShellContext.Provider
      value={{
        title,
        description,
        canonical: createCanonicalHref(canonical || router.asPath),
        index,
        image: image || defaultOgImage.toString(),
        keywords,
        brandedTitle,
      }}
    >
      {children}
      <BottomTabBar />
    </AppShellContext.Provider>
  );
};

AppShell.Meta = Meta;
AppShell.Header = Header;
AppShell.Main = Main;
AppShell.Footer = Footer;
AppShell.DiscountBanner = DiscountBanner;
AppShell.AccountPausedBanner = AccountPausedBanner;
AppShell.OldVippsBanner = OldVippsBanner;
