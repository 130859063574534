import clsx from "clsx";

import { ChevronDown } from "@chef/icons/large";

interface ChevronProps {
  className?: string;
}

export const Chevron = ({ className }: ChevronProps) => (
  <ChevronDown className={clsx("w-3 h-3 shrink-0 mx-3", className)} />
);
