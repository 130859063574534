import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      PLAN: "Planlegg",
      CALENDAR: "Kalender",
      RECIPES: "Oppskrifter",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundeservice",
    },
    se: {
      PLAN: "Planera",
      CALENDAR: "Kalender",
      RECIPES: "Recept",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundservice",
    },
    dk: {
      PLAN: "Planlæg",
      CALENDAR: "Kalender",
      RECIPES: "Opskrifter",
      ACCOUNT: "Konto",
      CUSTOMER_SERVICE: "Kundeservice",
    },
  } as const
)[language];
