import clsx from "clsx";

import { Chip } from "@chef/components";
import { isEqualStrings } from "@chef/utils/equal";

type FilterChipsProps<T extends number | string> = {
  selectedClassName?: string;
  className?: string;
  values: string[];
  handleClick: (value: T) => void;
  highlight?: boolean;
  alwaysShowCount?: boolean;
  chipIcon?: "check" | "cross" | "none";
  items: {
    value: T;
    label: string;
    count: number;
    type: "default" | "highlighted" | "secondary";
    isAll: boolean;
  }[];
};

export const FilterChips = <T extends number | string>({
  selectedClassName,
  className,
  items,
  highlight = true,
  values,
  alwaysShowCount,
  chipIcon = "check",
  handleClick,
}: FilterChipsProps<T>) => {
  return items.map((item) => {
    const isHighlighted = item.type === "highlighted";

    const isSelected = values.some((v) =>
      isEqualStrings(v, item.value.toString()),
    );

    return (
      <li key={item.value} className={className}>
        <Chip
          onClick={() => handleClick(item.value)}
          selected={isSelected}
          chipIcon={chipIcon}
          highlighted={highlight && isHighlighted}
          className={clsx("tabular-nums", isSelected && selectedClassName)}
          id={item.value.toString()}
        >
          <span>{item.label}</span>
          {(!isSelected || item.isAll || alwaysShowCount) && (
            <span className="ml-2">({item.count})</span>
          )}
        </Chip>
      </li>
    );
  });
};
