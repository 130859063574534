type TimeblockLike =
  | {
      to: string;
    }
  | undefined;

export const isNightTimeDelivery = (timeblock: TimeblockLike) => {
  if (!timeblock) {
    return false;
  }

  return /0[0-9]:00:00/.test(timeblock.to);
};
