import { useRouter } from "next/router";
import { Banner } from "@chef/components";
import { BRAND_PATHS, PAYMENT_PARTNER_IDS } from "@chef/constants";
import { useBillingQuery } from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import { isAccountActive } from "@chef/helpers";

export const OldVippsBanner = () => {
  const router = useRouter();
  const { data: billingData } = useBillingQuery();

  if (!billingData) {
    return null;
  }

  const status = billingData.billing.status;
  if (!isAccountActive(status)) {
    return null;
  }

  const { paymentPartnerId, currentPaymentCard } = billingData.billing;
  const isOldVipps =
    isEqualStrings(paymentPartnerId, PAYMENT_PARTNER_IDS.ADYEN) &&
    isEqualStrings(currentPaymentCard.tokenType, "vipps");

  if (!isOldVipps) {
    return null;
  }

  return (
    <Banner onClick={() => router.push(BRAND_PATHS.PAYMENT_LINK)} highlighted>
      <span className="mr-2">Din betalingsmetode vil ikke lenger støttes</span>
      <span className="underline">
        <strong>Oppdater</strong>
      </span>
    </Banner>
  );
};
