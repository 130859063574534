import previewRecipes from "../data/preview-recipes.json";

type GetPreviewRecipesArgs = {
  week: number;
  year: number;
  preferenceId: string;
};

export type PreviewRecipe = {
  mainRecipeId: number | null;
  recipeId: number;
  recipeName: string;
  cookingTimeMax: number;
  cookingTimeMin: number;
  shelfLife: number | null;
  images: {
    priority: number;
    isFeatured: boolean;
    urls: {
      size: string;
      url: string;
    }[];
  };
  taxonomies: {
    taxonomyId: number;
    name: string;
    description: string;
    order: number;
    type: string;
  }[];
};

type PreviewRecipeEntry = {
  week: number;
  year: number;
  preferenceId: string;
  recipes: PreviewRecipe[];
};

export const getPreviewRecipes = ({
  week,
  year,
  preferenceId,
}: GetPreviewRecipesArgs): PreviewRecipe[] => {
  const data: PreviewRecipeEntry[] = previewRecipes.data;

  if (data.length === 0) {
    console.error("PreviewRecipes not found");
    return [];
  }

  let entry: PreviewRecipeEntry = data[0];

  if (week && year) {
    const e = data.find(
      (d) =>
        d.week === week && d.year === year && d.preferenceId === preferenceId,
    );

    if (!e) {
      console.error(
        `PreviewRecipes not found for week ${week} and year ${year} and preferenceId ${preferenceId}`,
      );
      return [];
    }

    entry = e;
  }

  return entry.recipes;
};
