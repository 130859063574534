import { jsonLdScriptProps } from "react-schemaorg";
import { Recipe as RecipeSchema, WithContext } from "schema-dts";
import Head from "next/head";
import { useRouter } from "next/router";

import { RecipeAndStepsQuery } from "@chef/state-management";
import { findFeaturedPortraitImage } from "@chef/state-management/helpers";
import {
  BRAND_DOMAIN,
  BRAND_NAME_FULL,
  LOCALE,
  LOGO,
  TAXONOMY_TYPES,
} from "@chef/constants";

interface RichSnippetProps {
  recipe: RecipeAndStepsQuery["recipeAndSteps"];
}

const stripHTMLTags = (str: string) => str.replace(/(<([^>]+)>)/gi, "");

export const RichSnippet = ({ recipe }: RichSnippetProps) => {
  const router = useRouter();

  const cookPrepTime = +recipe.cookingTimeMax / 2;
  const cookTimeTotal = +recipe.cookingTimeMax;

  const keywords = recipe.taxonomies
    .filter((t) => t.type === TAXONOMY_TYPES.CATEGORY)
    .map((t) => t.name)
    .join(", ");

  const { ingredientSections, stepSections, nutritionFacts } =
    recipe.instructions.portions.find(({ size }) => size === "4") || {};

  const ingredients = ingredientSections?.flatMap(({ ingredients = [] }) =>
    ingredients.map((ingredient) => {
      if (!ingredient.amount) {
        return ingredient.name;
      }

      return `${ingredient.amount} ${ingredient.ingredientAmountType} ${ingredient.name}`;
    }),
  );

  const instructions = stepSections?.flatMap(
    ({ sectionTitle = "", steps = [] }) =>
      steps
        .filter((step) => step.step.length > 0)
        .map((step) => ({
          "@type": "HowToStep" as const,
          ...(sectionTitle && { name: sectionTitle }),
          text: stripHTMLTags(step.step),
        })),
  );

  const image = findFeaturedPortraitImage(recipe);
  const thumbnail = findFeaturedPortraitImage(recipe, "small");

  const payload: WithContext<RecipeSchema> = {
    "@context": "https://schema.org",
    "@type": "Recipe",
    inLanguage: LOCALE,
    isAccessibleForFree: true,
    publisher: {
      "@type": "Organization",
      name: BRAND_NAME_FULL,
      logo: {
        "@type": "ImageObject",
        url: LOGO.DEFAULT,
      },
      url: `https://www.${BRAND_DOMAIN}`,
    },
    url: `https://${BRAND_DOMAIN}${router.asPath}`,
    image: image?.url,
    thumbnailUrl: thumbnail?.url || image?.url,
    description: recipe.recipeDescription,
    prepTime: `PT${cookPrepTime}M`,
    cookTime: `PT${cookPrepTime}M`,
    totalTime: `PT${cookTimeTotal}M`,
    recipeIngredient: ingredients,
    recipeInstructions: instructions,
    recipeYield: "4",
    recipeCategory: "Dinner",
    nutrition: {
      "@type": "NutritionInformation",
      calories: `${nutritionFacts?.kcalPerPortion || 0} kcal`,
    },
    keywords,
  };

  if (recipe.recipeNameHeadline) {
    payload.name = recipe.recipeNameHeadline;
    payload.alternateName = recipe.recipeName;
  } else {
    payload.name = recipe.recipeName;
  }

  if (recipe.averageRating !== null) {
    payload.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: recipe.averageRating.toString(),
      reviewCount: recipe.numberOfRatings,
    };
  }

  const props = jsonLdScriptProps<RecipeSchema>(payload);

  return (
    <Head>
      <script {...props} />
    </Head>
  );
};
