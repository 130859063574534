import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { Close } from "@chef/icons/small";
import { FILTER_CATEGORIES, language } from "@chef/constants";
import { Card, Chip, ScrollableWrapper } from "@chef/components";
import { useFlag } from "@chef/feature-flags";
import { isEqualStrings } from "@chef/utils/equal";
import { isEmptyArray } from "@chef/utils/array";

import { Modal } from "../modal/Modal";
import { FilterChips } from "./FilterChips";

import { intl } from "./FilterNavigation.Intl";

interface FilterNavigationProps<T extends number | string> {
  items: {
    value: T;
    label: string;
    count: number;
    type: "default" | "highlighted" | "secondary";
    isAll: boolean;
  }[];
  value: T;
  onChange: (newValue: T) => void;
  resetFilters?: () => void;

  showGradients?: boolean;
  leftClassName?: string;
  rightClassName?: string;
  className?: string;
}

export const FilterNavigation = <T extends number | string>({
  items = [],
  value,
  onChange = () => null,
  showGradients = true,
  leftClassName = "bg-gradient-to-r from-white via-white",
  rightClassName = "bg-gradient-to-l from-white via-white",
  className,
  resetFilters,
}: FilterNavigationProps<T>) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const abtest = useFlag("global_filter_navigation");

  useEffect(() => {
    if (!filterModalOpen || !modalRef.current) {
      return;
    }
    if (window.innerWidth > 767) {
      modalRef.current.style.marginTop = "200px";
      return;
    }
    const modalHeight = modalRef.current.clientHeight;
    const windowHeight = window.innerHeight;

    const topMargin =
      windowHeight > modalHeight ? windowHeight - modalHeight : 100;

    modalRef.current.style.marginTop = `${topMargin}px`;
  }, [filterModalOpen]);

  const handleClick = (value: T) => {
    onChange(value);
  };

  if (isEmptyArray(items)) {
    return (
      <div className="flex space-x-2">
        {[...Array(3)].map((i) => (
          <Chip className="w-20 animate-pulse" key={i} />
        ))}
      </div>
    );
  }

  const isAnyHighlighted = items.some((item) => item.type === "highlighted");

  const vals = value
    .toString()
    .split(",")
    .filter((v) => v !== "");

  const filterCount = items.find((i) =>
    isEqualStrings(i.value.toString(), value.toString()),
  )?.count;

  const allCount = items.find((i) => i.isAll)?.count;

  const showcasedItems = items
    .filter(
      (item) =>
        item.type === "highlighted" ||
        (isEqualStrings(item.value.toString(), value.toString()) &&
          !item.isAll),
    )
    .sort((a, _) => (a.type === "highlighted" ? 1 : -1));

  return (
    <>
      <div className={className}>
        <ScrollableWrapper
          language={language}
          leftClassName={leftClassName}
          rightClassName={rightClassName}
          showGradients={showGradients}
        >
          {/* TODO: A/B test new mobile version */}
          <ul
            className={clsx(
              "relative flex gap-2 px-4 -mx-4 md:px-0 md:mx-0",
              abtest ? "lg:hidden" : "hidden",
            )}
            id="filter-navigate-mobile"
          >
            <li>
              <Chip
                className="tabular-nums"
                onClick={() => setFilterModalOpen(true)}
              >
                <div className="flex items-center gap-1">
                  <IconAdjustmentsHorizontal className="w-4 h-4" />
                  <span>{intl.ALL}</span>
                </div>
              </Chip>
            </li>
            <FilterChips
              selectedClassName="!bg-informationBG !border-information"
              items={showcasedItems}
              handleClick={handleClick}
              values={vals}
              highlight={false}
              chipIcon="cross"
              alwaysShowCount
            />
          </ul>
          {/* TODO: A/B test old and desktop version*/}
          <ul
            className={clsx(
              "relative flex gap-2 px-4 -mx-4 md:px-0 md:mx-0",
              abtest && "hidden lg:flex",
            )}
            id="filter-navigate-desktop"
          >
            <FilterChips
              items={items}
              handleClick={handleClick}
              values={vals}
            />
          </ul>
        </ScrollableWrapper>
        {resetFilters && (
          <div className="flex justify-between sticky top-0 z-[15] bg-background -mx-4 px-4 py-2 mt-2">
            <p className="text-sm">
              {!!filterCount &&
                !!allCount &&
                intl.SHOWING_X_OUT_OF_Y_PRODUCTS(filterCount, allCount)}
            </p>

            {value !== FILTER_CATEGORIES.ALL_CATEGORIES_SLUG && (
              <button
                onClick={resetFilters}
                className="text-sm underline text-primary"
              >
                {intl.REMOVE_FILTERS}
              </button>
            )}
          </div>
        )}
      </div>
      <Modal
        closeable={false}
        name="filters-modal"
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        animation="fly-up"
        size="sm"
        backToTop={false}
        gesture="pull-down-to-close"
      >
        <Card
          id="filter-modal"
          className="flex flex-col items-center gap-6 relative pb-10 md:!rounded-lg !rounded-t-lg !rounded-b-none"
          ref={modalRef}
        >
          <button
            onClick={() => setFilterModalOpen(false)}
            className="absolute top-0 right-0 z-20 flex items-center justify-center w-10 h-10 rounded-tr-lg rounded-bl-lg bg-grey-3"
            aria-label={intl.CLOSE}
          >
            <Close className="w-4 h-4" />
          </button>
          <div className="flex flex-col items-center w-full gap-8">
            {isAnyHighlighted && (
              <div className="w-full text-center">
                <p className="mb-3">
                  <strong>{intl.HIGHLIGHTED}</strong>
                </p>
                <ul className="flex flex-wrap justify-center gap-2">
                  <FilterChips
                    className="z-50"
                    selectedClassName="!bg-informationBG !border-information"
                    items={items.filter((item) => item.type === "highlighted")}
                    handleClick={(value) => {
                      handleClick(value);
                      setFilterModalOpen(false);
                    }}
                    values={vals}
                    highlight={false}
                    chipIcon="cross"
                    alwaysShowCount
                  />
                </ul>
              </div>
            )}
            <div className="w-full text-center">
              <p className="mb-3">
                <strong>{intl.ALL}</strong>
              </p>
              <ul className="flex flex-wrap justify-center gap-2">
                <FilterChips
                  className="z-50"
                  selectedClassName="!bg-informationBG !border-information"
                  items={items.filter(
                    (item) => item.type !== "highlighted" && !item.isAll,
                  )}
                  handleClick={(value) => {
                    handleClick(value);
                    setFilterModalOpen(false);
                  }}
                  values={vals}
                  highlight={false}
                  chipIcon="cross"
                  alwaysShowCount
                />
              </ul>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};
