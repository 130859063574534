import Image from "next/legacy/image";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonCircle, Card, Modal, Tag } from "@chef/components";
import {
  IDeviationProduct,
  ProductsByCategoriesQuery,
  resetStandaloneProductModal,
  selectStandaloneProductModal,
} from "@chef/state-management";
import {
  getImageFromVariation,
  getStockData,
  getPriceOfProductByVariation,
  getDiscountedAmount,
  getAttribute,
} from "@chef/state-management/helpers";
import { removeDuplicates } from "@chef/utils/removeDuplicates";
import { isNotEmptyArray } from "@chef/utils/array";
import { Minus, Plus } from "@chef/icons/small";
import { useIntercom } from "@chef/hooks";

import { CurrencyWrapper } from "./CurrencyWrapper";

import { intl } from "./StandaloneProductModal.Intl";

interface Props {
  showDiscount?: boolean;
  onAddToBasket?: (product: IDeviationProduct) => void;
  basket?: IDeviationProduct[];
}

type NutritionFacts =
  ProductsByCategoriesQuery["productsByCategories"][number]["products"][number]["variations"][number]["contents"]["nutritionFacts"];

const groupNutritionFactsByIngridient = (facts?: NutritionFacts) => {
  if (!facts) {
    return [];
  }

  const groupedFacts = [...facts]
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce(
      (acc, fact) => {
        if (!fact.ingredientName) {
          acc["default"].push(fact);
        } else if (!acc[fact.ingredientName]) {
          acc[fact.ingredientName] = [fact];
        } else {
          acc[fact.ingredientName].push(fact);
        }

        return acc;
      },
      { default: [] } as Record<string, NutritionFacts>,
    );

  return Object.entries(groupedFacts);
};

export const StandaloneProductModal = ({
  showDiscount,
  onAddToBasket,
  basket,
}: Props) => {
  const dispatch = useDispatch();
  const { open, productByCategoryVariation: variation } = useSelector(
    selectStandaloneProductModal,
  );

  useIntercom(!open);

  if (!open || !variation) {
    return null;
  }

  const product = variation.product;

  const name = variation.name || variation.product.name;

  const description =
    (variation && getAttribute("short_description", variation)) ||
    product.description;

  const featuredImage = getImageFromVariation({ variation, isFeatured: true });

  const price = getPriceOfProductByVariation(variation);
  const discountedAmount = getDiscountedAmount(variation);
  const isOnSale = showDiscount && !!discountedAmount;
  const originalPrice = price + discountedAmount;

  const productInBasket = basket?.find(
    (b) => b.productId === variation.product.productId,
  );

  const productCount = productInBasket?.quantity || 0;

  const onDecrementItem = () => {
    if (!onAddToBasket) {
      return;
    }

    onAddToBasket({
      productId: product.productId,
      productTypeId: product.productTypeId,
      quantity: productCount - 1,
      variationId: variation.variationId,
      price,
    });
  };

  const onIncrementItem = () => {
    if (!onAddToBasket) {
      return;
    }

    onAddToBasket({
      productId: product.productId,
      productTypeId: product.productTypeId,
      quantity: productCount + 1,
      variationId: variation.variationId,
      price,
    });
  };

  const { stockState, maxPurchasableAmount } = getStockData({
    addedQuantity: productInBasket?.quantity || 0,
    variation,
  });

  const nutritionFacts = groupNutritionFactsByIngridient(
    variation.contents?.nutritionFacts,
  );

  return (
    <Modal
      show={open}
      onClose={() => {
        dispatch(resetStandaloneProductModal());
      }}
      outerClassName="min-h-screen flex flex-col max-w-screen-lg m-auto"
      innerClassName="flex flex-col grow"
      closeText={intl.CLOSE}
    >
      <Card noPadding className="flex flex-col w-full grow lg:p-12">
        <div className="grid gap-4 lg:grid-cols-2 grow">
          <div className="relative">
            <Image
              src={featuredImage}
              alt={name}
              layout="responsive"
              objectFit="cover"
              width="375"
              height="485"
              objectPosition="center center"
              sizes="(min-width: 768px) 50vw, 100vw"
            />

            {isOnSale && (
              <Tag className="absolute top-5 left-5 bg-highlight">
                {intl.OFFERS}
              </Tag>
            )}
          </div>

          <div className="p-6 space-y-4 lg:ml-12 lg:p-0">
            <h2 className="text-lg lg:text-xl">
              <strong>{name}</strong>
            </h2>

            {stockState && (
              <div className="my-2 text-xs text-error lg:hidden">
                {intl.stockState[stockState]}
              </div>
            )}

            <div className="flex flex-row items-center justify-between">
              {!isOnSale ? (
                <p>
                  <strong>
                    <CurrencyWrapper>{price}</CurrencyWrapper>
                  </strong>
                </p>
              ) : (
                <p>
                  <strong>
                    <CurrencyWrapper className="text-discount">
                      {price}
                    </CurrencyWrapper>
                  </strong>

                  <CurrencyWrapper className="ml-2 text-gray-500 line-through">
                    {originalPrice}
                  </CurrencyWrapper>
                </p>
              )}

              {onAddToBasket && (
                <div>
                  {stockState && (
                    <span className="hidden my-2 text-xs text-error lg:inline-block lg:mr-6">
                      {intl.stockState[stockState]}
                    </span>
                  )}

                  {stockState !== "sold-out" && productCount > 0 && (
                    <>
                      <ButtonCircle
                        small
                        onClick={onDecrementItem}
                        Icon={Minus}
                        id={`${variation.variationId}-minus-btn`}
                      />
                      <span className="mx-2">{productCount}</span>
                      <ButtonCircle
                        small
                        primary={productCount > 0}
                        onClick={onIncrementItem}
                        Icon={Plus}
                        disabled={
                          stockState === "maximum-amount-added" ||
                          +maxPurchasableAmount === productCount // +maxCount === count is kept for backward compability, stockState should from now on
                        }
                        id={`${variation.variationId}-add-btn`}
                      />
                    </>
                  )}

                  {stockState !== "sold-out" && productCount === 0 && (
                    <Button
                      IconLeft={Plus}
                      onClick={onIncrementItem}
                      outlined
                      tiny
                      secondary
                    >
                      {intl.ADD}
                    </Button>
                  )}
                </div>
              )}
            </div>

            {description !== "." && description !== "False" && (
              <p
                dangerouslySetInnerHTML={{
                  __html: unescape(description),
                }}
              />
            )}

            {variation.contents && (
              <dl>
                <dt>
                  <strong>{intl.CONTENTS}</strong>
                </dt>

                <dd className="mt-1">
                  <strong>{intl.NETTOWEIGHT}:</strong>{" "}
                  {variation.contents.nettoWeight}{" "}
                  {variation.contents.unitLabelName}
                </dd>

                <dd>
                  <strong>{intl.INGREDIENTS}:</strong>{" "}
                  {variation.contents.ingredient}
                </dd>

                {isNotEmptyArray(variation.contents.allergies) && (
                  <dd className="mt-3">
                    <strong>{intl.ALLERGIES}:</strong>{" "}
                    {removeDuplicates(
                      variation.contents.allergies.map(
                        (allergies) => allergies.name,
                      ),
                    ).join(", ")}
                  </dd>
                )}

                <dd>
                  <strong>{intl.SUPPLIER}:</strong>{" "}
                  {variation.contents.supplierName}
                </dd>

                <hr className="my-3" />

                <dt className="mb-3">
                  <strong>{intl.NUTRITION_FACTS}</strong>
                </dt>
                <dd className="flex flex-col gap-4">
                  {nutritionFacts.map(([ingredientName, facts]) => {
                    return (
                      <div key={ingredientName}>
                        {ingredientName !== "default" && (
                          <strong>{ingredientName}</strong>
                        )}
                        <ul>
                          {facts.map((f) => (
                            <li key={f.name}>
                              {f.name}: {f.value} {f.unit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </dd>
              </dl>
            )}
          </div>
        </div>
      </Card>
    </Modal>
  );
};
