import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CLOSE: "lukk",
      ALL: "Alle filtre",
      CHOOSE_CATEGORY: "Velg kategori",
      HIGHLIGHTED: "Fremhevet",
      REMOVE_FILTERS: "Fjern filtre",
      SHOWING_X_OUT_OF_Y_DISHES: (x: number, y: number) =>
        `Viser ${x} av ${y} middager`,
      SHOWING_X_OUT_OF_Y_PRODUCTS: (x: number, y: number) =>
        `Viser ${x} av ${y} produkter`,
    },
    se: {
      CLOSE: "stäng",
      ALL: "Alla filter",
      CHOOSE_CATEGORY: "Välj kategori",
      HIGHLIGHTED: "Utvalda",
      REMOVE_FILTERS: "Ta bort filter",
      SHOWING_X_OUT_OF_Y_DISHES: (x: number, y: number) =>
        `Visar ${x} av ${y} middagar`,
      SHOWING_X_OUT_OF_Y_PRODUCTS: (x: number, y: number) =>
        `Visar ${x} av ${y} produkter`,
    },
    dk: {
      CLOSE: "luk",
      ALL: "Alle filtre",
      CHOOSE_CATEGORY: "Vælg kategori",
      HIGHLIGHTED: "Fremhævet",
      REMOVE_FILTERS: "Fjern filtre",
      SHOWING_X_OUT_OF_Y_DISHES: (x: number, y: number) =>
        `Viser ${x} af ${y} retter`,
      SHOWING_X_OUT_OF_Y_PRODUCTS: (x: number, y: number) =>
        `Viser ${x} af ${y} produkter`,
    },
  } as const
)[language];
