import { FILTER_CATEGORIES, TAXONOMY_TYPES } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { slugify } from "@chef/helpers";

import {
  PickAndMixQuery,
  RecipesByProductIdListQuery,
} from "../graphql/generated";

interface IFilterCategory {
  value: string;
  label: string;
  count: number;
  type: "default" | "highlighted" | "secondary";
  isAll: boolean;
  description?: string;
}

export const allowedFilterCategoryTaxonomies = [
  TAXONOMY_TYPES.CATEGORY,
  TAXONOMY_TYPES.CAMPAIGN,
  TAXONOMY_TYPES.SPECIALFOOD,
] as string[];

type PickAndMixRecipe =
  | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
  | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];

type PickAndMixRecipeWithPrice = PickAndMixRecipe & {
  price?: number;
  productId?: never;
};

type PickAndMixRecipeWithPriceAndProductId = PickAndMixRecipe & {
  price?: number;
  productId: string;
};

const getFilterCategoriesFromRecipes = (
  recipes:
    | PickAndMixRecipeWithPrice[]
    | PickAndMixRecipeWithPriceAndProductId[],
  recommendations: Set<string> | null,
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null,
  filter: string,
  multiSelect?: boolean,
) => {
  const uniqueCategories: {
    [key: string]: IFilterCategory;
  } = {};

  if (favorites !== null) {
    uniqueCategories[FILTER_CATEGORIES.FAVORITES_SLUG] = {
      value: FILTER_CATEGORIES.FAVORITES_SLUG,
      label: FILTER_CATEGORIES.FAVORITES,
      count: 0,
      type: "secondary",
      isAll: false,
      description: undefined,
    };
  }

  for (const recipe of recipes) {
    const price = recipe.price || 0;

    const isRecommended = recommendations?.has(recipe.productId || "");

    const isFavorited = favorites?.some((f) => {
      const id = recipe.mainRecipeId || recipe.recipeId;
      return f.mainRecipeId === id || f.recipeId === id;
    });

    if (isFavorited) {
      const shouldIncrement = shouldShowRecipeByFilter({
        filter,
        recipe,
        price,
        favorites,
      });

      if (!multiSelect || shouldIncrement) {
        uniqueCategories[FILTER_CATEGORIES.FAVORITES_SLUG].count++;
      }
    }

    if (isRecommended && recipe.productId) {
      if (!uniqueCategories[FILTER_CATEGORIES.RECOMMENDATIONS_SLUG]) {
        uniqueCategories[FILTER_CATEGORIES.RECOMMENDATIONS_SLUG] = {
          value: FILTER_CATEGORIES.RECOMMENDATIONS_SLUG,
          label: FILTER_CATEGORIES.RECOMMENDATIONS,
          count: 0,
          type: "secondary",
          isAll: false,
          description: undefined,
        };
      }

      const shouldIncrement = shouldShowRecipeByFilter({
        filter,
        recipe,
        price,
        recommendations,
        favorites,
        productId: recipe.productId,
      });

      if (!multiSelect || shouldIncrement) {
        uniqueCategories[FILTER_CATEGORIES.RECOMMENDATIONS_SLUG].count++;
      }
    }

    for (const tax of recipe.taxonomies) {
      if (!allowedFilterCategoryTaxonomies.includes(tax.type)) {
        continue;
      }

      // create if it doesn't exist
      const slugifiedName = slugify(tax.name);
      if (!uniqueCategories[slugifiedName]) {
        uniqueCategories[slugifiedName] = {
          value: slugifiedName,
          label: tax.name,
          description: tax.description,
          count: 0,
          type:
            tax.type === TAXONOMY_TYPES.CAMPAIGN ? "highlighted" : "default",
          isAll: false,
        };
      }

      if (recipe.productId) {
        const shouldIncrement = shouldShowRecipeByFilter({
          filter,
          recipe,
          price,
          recommendations,
          favorites,
          productId: recipe.productId,
        });

        if (!multiSelect || shouldIncrement) {
          uniqueCategories[slugifiedName].count++;
        }
      } else {
        const shouldIncrement = shouldShowRecipeByFilter({
          filter,
          recipe,
          price,
          favorites,
        });

        if (!multiSelect || shouldIncrement) {
          uniqueCategories[slugifiedName].count++;
        }
      }
    }
  }

  return Object.values(uniqueCategories);
};

export const generateFilterCategoriesFromRecipes = (args: {
  recipes:
    | PickAndMixRecipeWithPrice[]
    | PickAndMixRecipeWithPriceAndProductId[]
    | PickAndMixRecipe[];
  currentFilter: string;
  recommendations: Set<string> | null;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  excludeAll?: true;
  multiSelect?: boolean;
  myPicks?: { amount: number };
}) => {
  const {
    recipes,
    currentFilter,
    excludeAll,
    recommendations,
    favorites,
    multiSelect,
  } = args;

  const filterCategories = getFilterCategoriesFromRecipes(
    recipes,
    recommendations,
    favorites,
    currentFilter,
    multiSelect,
  );

  // sort in order of highlighted, secondary, default
  filterCategories.sort((a, b) => {
    if (a.type === "highlighted" && b.type !== "highlighted") {
      return -1;
    }

    if (b.type === "highlighted" && a.type !== "highlighted") {
      return 1;
    }

    if (a.type === "secondary" && b.type !== "secondary") {
      return -1;
    }

    if (b.type === "secondary" && a.type !== "secondary") {
      return 1;
    }

    return a.label.localeCompare(b.label);
  });

  if (!excludeAll) {
    filterCategories.unshift({
      value: FILTER_CATEGORIES.ALL_CATEGORIES_SLUG,
      label: FILTER_CATEGORIES.ALL_CATEGORIES,
      count: recipes.length,
      type: "default",
      isAll: true,
      description: undefined,
    });
  }

  return filterCategories;
};

interface IShouldShowRecipeByFilterBase {
  recipe:
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];
  filter: string;
  searchValue?: string;

  recommendations?: never;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  productId?: never;

  price: number;
}

interface IShouldShowRecipeByFilterWithProductId {
  recipe:
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number];
  filter: string;
  searchValue?: string;

  recommendations: Set<string> | null;
  favorites: { recipeId: number; mainRecipeId: number | null }[] | null;
  productId: string;

  price: number;
}

export const shouldShowRecipeByFilter = (
  args: IShouldShowRecipeByFilterBase | IShouldShowRecipeByFilterWithProductId,
) => {
  const { recipe, filter, searchValue, recommendations, productId, favorites } =
    args;

  if (searchValue) {
    // Show recipe if the id, name or taxonomies match
    const matchValues = new Set<string>();

    // Add ID
    matchValues.add(recipe.recipeId.toString());

    // Add recipe name
    matchValues.add(recipe.recipeName.toLowerCase());

    // Add recipe taxonomies
    for (const taxonomy of recipe.taxonomies) {
      if (allowedFilterCategoryTaxonomies.includes(taxonomy.type)) {
        matchValues.add(taxonomy.name.toLowerCase());
      }
    }

    // Check if any of the match values match the search value
    for (const mv of matchValues) {
      for (const sv of searchValue.split(" ").filter(Boolean)) {
        if (mv.includes(sv.toLowerCase())) {
          return true;
        }
      }
    }

    return false;
  }

  if (!filter) {
    return true;
  }

  if (filter === FILTER_CATEGORIES.ALL_CATEGORIES_SLUG) {
    return true;
  }

  const filters = filter.split(",").filter((v) => v !== "");

  return filters.every((filter) => {
    if (filter === FILTER_CATEGORIES.RECOMMENDATIONS_SLUG) {
      if (!recommendations || !productId) {
        console.warn(
          `Filter is ${filter}, but no recommendations | productId was passed`,
          productId,
          recommendations,
        );

        return false;
      }

      return recommendations.has(productId.toLowerCase());
    }

    if (filter === FILTER_CATEGORIES.FAVORITES_SLUG) {
      return favorites?.some(
        (f) =>
          f.mainRecipeId === recipe.mainRecipeId ||
          f.recipeId === recipe.recipeId,
      );
    }

    return recipe.taxonomies.some((taxonomy) => {
      if (!allowedFilterCategoryTaxonomies.includes(taxonomy.type)) {
        return false;
      }

      return isEqualStrings(filter, slugify(taxonomy.name));
    });
  });
};
